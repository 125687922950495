.ChartSwithcer {
  button {
    width: auto;
    height: auto;
    font-size: 16px;
    padding: 8px 12px;
    font-weight: 500;
    border: #d6d6d6 1px solid;
    background-color: transparent;
    font-family: Gotham;
    transition: background 0.25s ease-in-out;
    border-radius: 0;
    &.active {
      background-color: #d6d6d6;
    }
  }

}