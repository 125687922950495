.footer {
  background-color: #eeeeee;
  height: 80px;
  width: 100%;
}



.footer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}


