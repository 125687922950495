.Loader_wrap {
  text-align: center;
  height: 100%;
  position: relative;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Loader {
  display: inline-block;
  // position: absolute !important;
  width: 45px;
  height: 45px;
  // left: 50%;
  // top: 50%;
  // transform: translateX(-50%) translateY(-50%) translateZ(0);
	border-radius: 50%;

  font-size: 9px;
 
  text-indent: -9999em;
  border-top: 1.1em solid rgba(22,15,9, 0.2);
  border-right: 1.1em solid rgba(22,15,9, 0.2);
  border-bottom: 1.1em solid rgba(22,15,9, 0.2);
  border-left: 1.1em solid #160f09;
  // -webkit-transform: translateZ(0);
  // -ms-transform: translateZ(0);
  
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  
	&:after {
		width: 45px;
		height: 45px;
		border-radius: 50%;
	}


}
// .Loader div {
//   position: absolute;
//   border: 4px solid #191f20;
//   opacity: 1;
//   border-radius: 50%;
//   animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
// }
// .Loader div:nth-child(2) {
//   animation-delay: -0.5s;
// }


// @keyframes lds-ripple {
//   0% {
//       top: 28px;
//       left: 28px;
//       width: 0;
//       height: 0;
//       opacity: 1;
//   }
//   100% {
//       top: -1px;
//       left: -1px;
//       width: 58px;
//       height: 58px;
//       opacity: 0;
//   }
// }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-50%) rotate(360deg);
    transform: translateY(-50%) rotate(360deg);
  }
}