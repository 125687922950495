// .Select {
//   margin-bottom: 15px;
// }

// .Select label {
//   margin: 0 0 3px 0;
//   padding: 0;
//   font-weight: bold;
//   display: block;
// }

// .Select select {
//   display: block;
//   box-sizing: border-box; 
//   border: 1px solid #bebebe;
//   margin: 0 0 5px;
//   height: 29px;
//   width: 100%;
//   outline: none;
//   transition: all 300ms ease-in-out;
//   height: 31px;
// 	option:hover {
// 		background-color: #d9c7c7 !important;
// 	}
// }

.select-wrapper {
	position: relative;
	user-select: none;
	width: 100%;
	max-width: 220px;
	margin-bottom: 15px;
}
.select {
	position: relative;
	display: flex;
	flex-direction: column;

}

.label {
	margin: 0 0 8px 0;
  padding: 0;
  font-weight: bold;
  display: block;
}
.select__trigger {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px 0 8px;
	font-size: 16px;
	// color: #3b3b3b;
	height: 34px;
	line-height: 34px;
	background-color: transparent !important;
	cursor: pointer;
	// border-width: 2px 0 2px 0;
	// border-style: solid;
	// border-color: #394a6d;
	border: 1px solid #bebebe;
}
.custom_options {
	position: absolute;
	display: block;

	top: 100%;
	left: 0;
	right: 0;
	border: 1px solid #bebebe;
	border-top: 0;
	background: #fff;
	transition: all 0.5s;
	opacity: 0;
	height: 0;
	visibility: hidden;
	pointer-events: none;
	z-index: 2;
}
.select.open .custom_options {
	opacity: 1;
	visibility: visible;
	pointer-events: all;
	height: auto;
}
.custom_option {
	position: relative;
	display: block;
	padding: 8px 0 8px 8px;
	text-align: left;
	// font-size: 22px;
	// font-weight: 300;
	// color: #3b3b3b;
	// line-height: 60px;
	cursor: pointer;
	transition: all 0.5s;
}
.custom_option:hover {
	cursor: pointer;
	background-color: #b4b3b3;
}
.custom_option.selected {
	background-color: #b4b3b3;
}

.arrow {
	position: relative;
	// height: 15px;
	// width: 15px;
  width: 0; 
  height: 0; 
	border-bottom: none;
	border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #160f09;		

	transition: all 0.25s;
}

.open .arrow {
	position: relative;
	// height: 15px;
	// width: 15px;
  width: 0; 
  height: 0; 
	border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #160f09;	
	border-top: none;
}
// .arrow::before, .arrow::after {
// 	content: "";
// 	position: absolute;
// 	bottom: 0px;
// 	width: 0.15rem;
// 	height: 100%;
// 	transition: all 0.5s;
// }
// .arrow::before {
// 	left: -5px;
// 	transform: rotate(45deg);
// 	background-color: #394a6d;
// }
// .arrow::after {
// 	left: 5px;
// 	transform: rotate(-45deg);
// 	background-color: #394a6d;
// }
// .open .arrow::before {
// 	left: -5px;
// 	transform: rotate(-45deg);
// }
// .open .arrow::after {
// 	left: 5px;
// 	transform: rotate(45deg);
// }