@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Book.otf") format("opentype");
  font-weight: 350;
}

* {
  margin: 0;
  padding: 0;
}


body {
  height: 100vh;
  font-family: Gotham;
  font-weight: 350;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	overflow-y: auto;
}

body.fixed {
	overflow-y: hidden;
}



h1 {
  font-size: 26px;
  text-align: center;
  font-weight: 500;
}

h2 {
  font-size: 23px;
  text-align: center;
  font-weight: 500;
}




