.TabTags {
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: 500;
  border: 1px solid #d6d6d6;
  transition: background 0.25s ease-in-out;
}

.TabTags.active {
  background-color: #d6d6d6;
}